/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */

import $ from 'jquery';
import 'slick-carousel';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import { Autocomplete } from 'stimulus-autocomplete';
import '../scss/application';

// ActionText
import "@rails/actiontext"


Rails.start();
Turbolinks.start();

// Require syntax is required, and this must come after Turbolinks.start()
require('@client-side-validations/client-side-validations');

// React
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext); // Support component names relative to this directory:

// Stimulus
const application = Application.start();
const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
application.register('autocomplete', Autocomplete);
document.addEventListener('turbolinks:before-cache', () => {
  application.controllers.forEach(controller => {
    if (typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});

// import all image files in a folder:
require.context('../images', true);

function loadAvatarPicker() {
  // enable slick carousel
  $('.avatar-carousel').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
  });
  // initialize selected css on load
  $("[data-name='"+ $('#child_avatar_name').val() +"']").addClass("selected");
  // handle selecting a avatar
  $(".avatar-image").on( "click", function() {
    const selected_value = $( this ).data("name");
    $('#child_avatar_name').val(selected_value);
    $(".avatar-image").removeClass('selected');
    $(this).addClass( "selected" );
  });
}

// Avatar Picker
$(document).on('turbolinks:load', function() {
  // Only load this js on children edit page
  if (document.getElementsByClassName("avatar-carousel").length > 0) {
    loadAvatarPicker();
  }

  const main_nav = document.querySelector('.main_nav')
  const nav_ul = document.querySelector('.nav')
  const menu = document.querySelector('.menu')
  const menu_close = document.querySelector('.menu_close')
  const target = document.querySelector('.content-main')
  const title = document.querySelector('.content-main h1')
  const logo = document.querySelector('.logo')
  const mobile_nav = document.querySelector('.mobile_nav')
  
  function showMenu() {
    nav_ul.classList.toggle('show_nav')
    menu.classList.toggle('hide')
    menu_close.classList.toggle('hide')
  }
  
  menu.addEventListener('click', showMenu)
  menu_close.addEventListener('click', showMenu)
  
  let observer = new IntersectionObserver(
    e => {
      const windowWidth = window.innerWidth
      if (windowWidth < 800) return
  
      const isVisible = e[0].isIntersecting
  
      if (isVisible) {
        main_nav.classList.remove('sticky_nav')
        title.classList.remove('gap')
        logo.classList.remove('show')
        mobile_nav.classList.remove('show')
      } else if (!isVisible) {
        main_nav.classList.add('sticky_nav')
        title.classList.add('gap')
        logo.classList.add('show')
        mobile_nav.classList.add('show')
      }
    },
    { threshold: 0.25 },
  )
  
  observer.observe(target)
  

})

