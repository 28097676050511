import { Controller } from 'stimulus';

/*
 * This controller is responsble for filtering the branch listing based on
 * library selection
 */
export default class extends Controller {
  static targets = ['libraries', 'branches'];

  connect() {
    this.filterBranches();
  }

  /*
   * Populates the branchesTarget select box with the branches matching the
   * selected library in the librariesTarget
   */
  filterBranches() {
    const value = this.librariesTarget.value;

    if (value.length > 0) {
      fetch(`${this.data.get('url')}?id=${value}`)
        .then(response => response.text())
        .then(html => {
          const branchSelectValue = this.branchesTarget.value;

          /*
           * Continue if the branch blank, or the branch is
           * part of the library system payload
           */
          if (branchSelectValue !== 'Select your branch' && branchSelectValue !== '' && html.search(this.branchesTarget.value) > -1) {
            return;
          }

          const isEmpty = html.trim().length === 0;
          this.branchesTarget.innerHTML = html;
          this.branchesTarget.disabled = isEmpty;

          if (isEmpty) {
            this.branchesTarget.innerHTML =
              '<option value="">Select Library Above</option>';
          }
        });
    }
  }
}
