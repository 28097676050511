import { Controller } from 'stimulus';
import $ from 'jquery';
import { notify } from 'react-notify-toast';
import { Howl } from 'howler';

const checkSound = new Howl({
  src: ['/checkmark.wav'],
});

const handleCheckAnimationEnd = function() {
  this.classList.remove('animate__animated', 'animate__bounceIn', '');
  this.removeEventListener('animationend', handleCheckAnimationEnd);
};

/*
 * This controller is responsible for submitting reading entries via XHR and
 * re-rendering the reading entry form state to reflect the submission.
 */
export default class extends Controller {
  static targets = ['check'];

  update(e) {
    e.preventDefault();

    const $form = $(this.element);
    const url = $form.attr('action');
    const data = $form.serialize();

    const checkEl = this.checkTarget;
    checkEl.classList.add('animate__animated');
    checkEl.addEventListener('animationend', handleCheckAnimationEnd);

    // if (checkEl.classList.contains('animate__bounceIn')) {
    checkSound.play();
    // }

    fetch(`${url}?${data}`, {
      method: 'POST',
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.body);
        }

        return response;
      })
      .then(response => response.text())
      .then(html => {
        // Updates form with the new check state
        this.element.innerHTML = html;
      })
      .then(() => {
        // Dispatch update-badges event so badges controller can update itself
        const event = new CustomEvent('update-badges');
        window.dispatchEvent(event);
      })
      .catch(() => {
        notify.show('There was an error saving this reading entry', 'error');
      });
  }
}
