import { Controller } from 'stimulus';
import { notify } from 'react-notify-toast';
import 'animate.css';
import { Howl } from 'howler';

const badgeSound = new Howl({
  src: ['/badge.wav'],
});

const ANIMATION_ACTIVE = ['animate__animated', 'animate__rubberBand'];
const ANIMATION_INACTIVE = ['animate__animated', 'animate__flipOutX'];

const handleBadgeAnimationEnd = function() {
  this.classList.remove(...ANIMATION_ACTIVE, ...ANIMATION_INACTIVE);
  this.removeEventListener('animationend', handleBadgeAnimationEnd);
};

/*
 * This controller is responsible for syncing the badges UI with server state.
 */
export default class extends Controller {
  update() {
    fetch(this.data.get('url'))
      .then(resp => resp.json())
      .then(({ badges }) => {
        const matches = this.element.className.match(/unlocked-([0-9]+)/);

        this.element.className = `badges unlocked-${badges}`;

        const nextBadgeCount = Number(badges);
        const prevBadgeCount = Number(matches[1]);
        let el;
        let notificationMessage;
        if (nextBadgeCount === 7) {
          notificationMessage =
            "Congratulations! You\'ve completed meeting your SRC reading goals!";
          document
            .getElementById('certificate-congrats')
            .classList.remove('certificate-hide');
          document
            .getElementById('badge-caption')
            .classList.remove('certificate-hide');
          document
            .getElementById('seventh-badge')
            .classList.add('badge-caption-margin');
        } else if (nextBadgeCount === 1) {
          notificationMessage =
            'Way to go! You\'ve completed 7 days of meeting your reading goals! Keep going!';
        } else if (nextBadgeCount === 3) {
          notificationMessage =
            'Awesome! You\'ve completed 21 days of meeting your reading goals! You\'re almost half way there!';
        } else if (nextBadgeCount === 5) {
          notificationMessage =
            'You\'re doing amazing! You\'ve completed 35 days of meeting your reading goals!';
        } else if (nextBadgeCount === 6) {
          notificationMessage =
            'You\'re a rockstar! One more week of reading meeting your SRC reading goals!';
          document
            .getElementById('certificate-congrats')
            .classList.add('certificate-hide');
          document
            .getElementById('badge-caption')
            .classList.add('certificate-hide');
          document
            .getElementById('seventh-badge')
            .classList.remove('badge-caption-margin');
        } else if (nextBadgeCount === 9) {
          notificationMessage =
            'Congrats for unlocking all of the badges!';
        } else {
          notificationMessage =
            'Congratulations, you earned a new badge! Keep going!';
        }
        if (nextBadgeCount > prevBadgeCount) {
          notify.show(notificationMessage, 'success');
          el = this.element.children[nextBadgeCount - 1];
          el.classList.add(...ANIMATION_ACTIVE);
          badgeSound.play();
        } else if (prevBadgeCount > nextBadgeCount) {
          Array.from(this.element.children).forEach(child => {
            child.classList.remove(...ANIMATION_ACTIVE);
          });

          el = this.element.children[prevBadgeCount - 1];
          el.classList.add(...ANIMATION_INACTIVE);
        }

        if (el) {
          el.addEventListener('animationend', handleBadgeAnimationEnd);
        }
      });
  }
}
