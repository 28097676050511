import React from 'react';
import PropTypes from 'prop-types';

const Callout = ({ icon, text, type }) => (
  <div className={`${type} callout`}>
    <i className={`fa fa-${icon}`} />
    {text}
  </div>
);

export const PrimaryCallout = ({ text }) => (
  <Callout icon="info-circle" text={text} type="primary" />
);

export const SuccessCallout = ({ text }) => (
  <Callout icon="check" text={text} type="success" />
);

export const WarningCallout = ({ text }) => (
  <Callout icon="warning" text={text} type="warning" />
);

export const AlertCallout = ({ text }) => (
  <Callout icon="times-circle" text={text} type="alert" />
);

Callout.propTypes = {
  icon: PropTypes.oneOf(['info-circle', 'check', 'warning', 'times-circle'])
    .isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'success', 'warning', 'alert']).isRequired,
};
PrimaryCallout.propTypes = { text: PropTypes.string.isRequired };
SuccessCallout.propTypes = { text: PropTypes.string.isRequired };
WarningCallout.propTypes = { text: PropTypes.string.isRequired };
AlertCallout.propTypes = { text: PropTypes.string.isRequired };
